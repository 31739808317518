<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Major Project: Individual Reflection
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Individual Reflection</h2>
                      <p></p>
                      <h3>Round 1</h3>
                      <h4>Learning Experiences</h4>
                      <p class="description">
As we were introduced to this stakeholder project, I felt a little uneasy, as group projects always seem to feel that way, especially online. This is because everyone had different schedules, and our project had two roles: one was the team leader and the designer, even though there were three of us in the group. Usually, when I see this, I get a little concerned because that is one person left out with no role. The other members in my group were already very experienced in LDE, which caused quick discussions and feedback on the problems we were given. By the time I could interact, there seemed to be a solution already. I still voiced my concerns and feedback if I had any. This may seem like a bad experience, but in reality, this helped me learn even more from the two members of my group and gain more knowledge based on their years of LDE experience. I got to see how quickly they could come up with solutions for helping a school district create a Canvas course for training, but I also looked through their eyes at how they broke apart other proposals to provide feedback which allowed me to see what they look for specifically.
                      </p>
                      <p> Overall, I learned a lot working with two others on these proposals, and I like how our prosal came out. As we move forward and handle the feedback, I feel I can take what I have learned and apply that in a better way to make the adjustments necessary to our proposal and be more beneficial to the team.</p>
                      <h4>Insights</h4>
                      <p class="description">
                        During this first round of proposals, things have been very insightful with having two experienced members who took the leader and design role.  This allowed me to shadow and pop in and out with my feedback if needed. As mentioned above, being in this situation was helpful to my understanding of what we were doing as they had experience in this type of project already, whereas I have not. I got to learn a lot, but during the proposal work, there were times I questioned whether I was doing enough to help the group, and the idea of why the groups had three members instead of two kept arising. I like the idea that everyone would have a role in the group to keep everyone on track, but in our case, there are only two roles and three members, so it always felt like I was sitting in the background. As I mentioned, I learned a lot by not having a role for this first round, but I did question whether I would have learned more by being able to apply my thoughts more. Even with the current outcome, I feel as though I learned a lot, and as we move toward the second round, I feel more confident that I will be able to be more helpful to my team. I will push harder to get my feedback out there at the fastest pace to keep up with the more experienced members.
                      </p>
                      <h3>Round 2</h3>
                      <h4>Learning Experiences</h4>
                      <p class="description">
                        Going into the second stakeholder project, I felt better prepared after seeing how my group handled the first round. I felt better because we were in a groove of communication. After feeling better going into this round, I volunteered to be the design lead because I felt like, with my user experience background, I could create a great design visual of the proposal we received. After the first round, I felt ready to go this round. Once we got to the design stage, I felt very prepared, and I felt pretty good about the design I came up with. I got to use Figma to create a prototype of the proposed first aid application, and I feel like it visually gave the group what they wanted and more. At the point of writing this, we haven't received the feedback, but I feel confident that we did a good job.                      </p>
                      <p> Overall, from everything I learned in the first round from my group, I felt much better going into this round and felt confident with the outcome that we presented. I feel as though if I didn't get to see what was done in the first round that, I wouldn't have been as confident going into the second round, so I am happy it worked out the way it did.</p>
                      <h4>Insights</h4>
                      <p class="description">
                        During the second round of proposals, I felt good about what we were doing, allowing me to give more feedback. We ended up having more discussions off of Canvas due to the notifications being sparse, and it went well. I learned a lot in the first round, so I was more willing to give my opinion on feedback and receive feedback on my design. From everything I got to learn from the first round, I got to apply it this second round, and I did an excellent job with what was presented. In the end, I didn't have doubts like I did in the first round and felt better prepared.                      </p>
                      <h3>Final Reflection</h3>
                      <h4>Learning Experiences</h4>
                      <p class="description">As the class ends, there is a lot to reflect on, from where I was at the beginning to now. At first, I was pretty uncomfortable about the things we covered and my group participation. There was a lot of unknown familiarity with the process of stakeholders and designs. Working with my group, the process became more familiar to me, allowing me to feel more confident. Going into the second round, I felt more comfortable and volunteered to be the designer lead. As the design lead, I created the proposed project using Figma. The outcome came out well, and the feedback from the team who proposed the project came back with good things to say. That feedback made me feel like I did an excellent job, as even though I was ready to take the design lead, there was still that voice criticizing me in the back of my head. With that said, I feel as though when the class started, I didn't know much, and now as the class ends, I feel very comfortable if a proposed idea comes my way and not second-guessing myself if I will be able to do it.</p>
                        
                      <h4>Insights</h4>
                      <p class="description">
                        I feel very comfortable with my abilities coming out of this class. Even though the ideas of what we covered weren't 'new,' I have never demonstrated them as I got to in this course which I genuinely appreciate. As I proceed further into my career, I thank this class for believing in my abilities and pushing forward to accomplish what is needed. As I went through the course, I probably went through all the phases of imposter syndrome to realize not to think that way. We got to collaborate in different ways, our team used Canvas which seemed to work out okay most of the time, but it was a first for using Canvas as the primary collaboration tool. We got to use different tools for designing. I got to experience Adobe Express for the first time to create a nice design presentation for the proposed idea and also got to use Figma for the designs I did and implement them in Adobe Express. For project management, again, we used Canvas to keep track of everything. Even though there are better ways to handle this and better tools for what we were doing, it worked for the most part. Between the collaboration and project management using Canvas, I would probably change that in the future just to create a more consistent workflow since some of our communication was missed due to notifications not being the best when working with Canvas. Overall I had a good experience, learned a lot, and got to use some great tools, but I also noticed the things that my team could have done better in the end.</p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870-2"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
